<style scoped>
.demo-upload-list {
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
<template>
  <div>
    <Upload
      ref="upload"
      :show-upload-list="false"
      :format="['jpg','jpeg','png','doc','docx','xls','xlsx']"
      accept='image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      :before-upload="handleBeforeUpload"
      action=""
      multiple
    >
      <div class="p-l-5 p-r-5">
        <a size="small" type="info" >
          添加文件并上传
        </a>
        <p class="remark">点击按钮并选择文件进行上传，只支持图片和文件类型</p>
      </div>
    </Upload>

    <Row :gutter="8">
      <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(file,fileIndex) in fileList" :key="'fileIndex_'+fileIndex">
        <template v-if="file.type.indexOf('image/')!==-1">
          <div class="demo-upload-list" :style="uploadSize">
            <img :src="getbasesrc(file)">
            <div class="demo-upload-list-cover">
              <!-- <Icon type="ios-eye-outline"></Icon> -->
              <Icon class="p-t-20"
                type="ios-trash-outline"
                @click.native="handleRemove(file)"
              ></Icon>
            </div>
          </div>
          <div>
            <Tooltip max-width="200" :content="file.name">
              {{file.name.length>10? file.name.substr(0,9)+'...':file.name}}
            </Tooltip>
          </div>
        </template>
        <template v-else>
          <div class="demo-upload-list" :style="uploadSize">
             <img  src="../../assets/images/WinWordLogoSmall.scale-180.png" height="50px" >
            <div class="demo-upload-list-cover">
              <Icon  class="p-t-20"
                type="ios-trash-outline"
                @click.native="handleRemove(file)"
              ></Icon>
            </div>
          </div>
          <div>
            <Tooltip max-width="200" :content="file.name">
              {{file.name.length>10? file.name.substr(0,9)+'...':file.name}}
            </Tooltip>
          </div>
        </template>
        <!-- <div>
                  <Progress :percent="image.percentage"></Progress>
                </div> -->

      </i-col>
    </Row>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'

export default {
  props: {
    size: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      fileList: [],
      percentage: 0
    }
  },
  methods: {
    handleBeforeUpload (file) {
      // let accept = ['image/*', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      // if (accept.includes(file.type)) {
      this.fileList.push(file)
      // } else {
      //   this.$Notice.warning({ desc: '不支持该文件' })
      // }
      return false
    },
    getbasesrc (file) {
      return window.URL.createObjectURL(file)
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleUpload (advertiserId, approvalId) {
      return new Promise((resolve, reject) => {
        if (this.fileList.length > 0 && advertiserId) {
          const param = new FormData()
          param.append('advertiserId', advertiserId)
          param.append('approvalId', approvalId)
          param.append('publisherId', this.$store.getters.token.userInfo.publisherId)

          param.append('type', 2)
          // param.append('type', 'ADVERTISER_FILE')

          this.fileList.forEach(file => {
            // if (file.type.indexOf('image/') !== -1) {
            //   param.append('imgFiles', file)
            // } else {
            param.append('files', file)
            // }
          })

          axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/orderfile/addfile',
            param,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'ooh-auth': getStorage('ooh-token')
              },
              withCredentials: true,
              onUploadProgress (progress) { // 处理上传文件进度条数据
                this.percentage = Math.round(progress.loaded / progress.total * 100)
              }
            }
          ).then(res => {
            this.fileList = []
            resolve(res.data)
          })
        } else {
          resolve(false)
        }
      })
    },
    handleReset () {
      this.fileList = []
    }
  },
  computed: {
    uploadSize () {
      if (this.size === 'small') {
        return { width: '60px', height: '60px' }
      } else {
        return { width: '120px', height: '120px' }
      }
    }
  }
}
</script>
