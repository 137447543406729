<style scoped>
.images {
  cursor: pointer;
  display: flex;
}
.images img {
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}
</style>
<template>
  <div class="m-t-10">
    <i-table stripe :data="records" :columns="titles"></i-table>
    <Modal
      title="素材清单"
      v-model="showApprovalDetail"
      width="1000"
      footer-hide
    >
      <Tabs value="material" ref="222" name="222" class="text-white" v-if="showApprovalDetail">
        <TabPane label="上刊素材" name="material">
          <div class="images clearfix">
            <Row :gutter="8" style="width: 100%">
              <i-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="6"
                v-for="(image, imgIndex) in material"
                :key="imgIndex"
              >
                <img
                  :src="image.fileKey"
                  :alt="image.fileName"
                  class="image"
                  :key="image.fileKey"
                />
              </i-col>
            </Row>
          </div>
        </TabPane>
        <TabPane label="资质材料" name="annex">
          <Table
          size="small"
          :columns="annexColumns"
          :data="annex"
          stripe
        ></Table>

        </TabPane>
      </Tabs>
    </Modal>
    <Modal
      title="附件上传"
      v-model="fileUploadModal"
      width="400"
      @on-ok="confirmUpload"
    >
      <fileUpload ref="fileUpload" :size="'small'"></fileUpload>
    </Modal>
  </div>
</template>

<script>
import { approvalfilelist, getapprovalinfo } from '@/api/order/orderfileV2'
import FileUpload from '@/components/smallcustomer/CustomerFileUpload'

import { downloadFileRequest } from '@/utils/download'
export default {
  components: {
    FileUpload
  },
  props: {
    advertiserId: {
      required: true
    }
  },
  data () {
    return {
      records: [],
      titles: [
        { title: '创建时间', key: 'createTime' },
        { title: '当前状态', key: 'statusName' },
        {
          title: '操作',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    getapprovalinfo({ approvalId: params.row.id }).then(
                      (res) => {
                        this.approvalInfo = res
                        this.material = res.fileVOList.filter(x => x.type === 1)
                        this.annex = res.fileVOList.filter(x => x.type === 2)
                        this.showApprovalDetail = true
                      }
                    )
                  }
                }
              },
              '素材详情'
            )
            const uploadButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.approvalId = params.row.id
                    this.fileUploadModal = true
                  }
                }
              },
              '补充资质材料'
            )
            return h('div', [detailButton, uploadButton])
          }
        }
      ],
      annexColumns: [
        { title: '名称', key: 'realFileName' },
        { title: '文件类型', key: 'mimeType' },
        { title: '文件大小（KB）', key: 'length' },
        { title: '创建时间', key: 'createTime' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      showApprovalDetail: false,
      approvalInfo: {},
      fileUploadModal: false,
      material: [],
      annex: []
    }
  },
  methods: {
    getApprovalList () {
      approvalfilelist({
        advertiserId: this.advertiserId,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }).then((data) => {
        this.records = data
      })
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    confirmUpload () {
      this.$refs.fileUpload.handleUpload(this.advertiserId, this.approvalId)
    }
  },
  created () {
    this.getApprovalList()
  }
}
</script>
