<template>
  <div>
    <div class="workplatform-title">
      {{ advertiser.name }} （{{ advertiser.orgCode }}）
    </div>
    <!-- 客户基本信息 -->
    <Row class="p-b-5 p-t-10">
      <i-col span="8"
        ><span class="title">客户类型 </span>{{ advertiser.typeName }}</i-col
      >
      <i-col span="8"
        ><span class="title">所属媒介主 </span
        >{{ advertiser.publisherName }}</i-col
      >
      <i-col span="8"
        ><span class="title">联系电话 </span>{{ advertiser.tel || "-" }}</i-col
      >
      <i-col span="8"
        ><span class="title">客户来源 </span
        >{{ advertiser.adFromName || "-" }}</i-col
      >
      <i-col span="8"
        ><span class="title">公司类型 </span
        >{{ advertiser.companyTypeName || "-" }}</i-col
      >
      <i-col span="8"
        ><span class="title">报备代理商 </span
        >{{ advertiser.reportCompanyName || "-" }}</i-col
      >
      <i-col span="8"
        ><span class="title">报备时间 </span>{{ advertiser.createTime }}</i-col
      >
      <!-- <i-col span="8" ><span class="title">地址 </span >{{advertiser.address}}</i-col > -->
      <i-col span="8"
        ><span class="title">客户备注信息 </span
        >{{ advertiser.description || "-" }}</i-col
      >
    </Row>

    <Tabs value="orders" ref="tit" name="tit" class="text-white">
      <TabPane label="订单列表" name="orders">
        <Table stripe :columns="orderColumn" :data="orders"></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="changePage"
          ></Page>
        </div>
      </TabPane>
      <TabPane label="上刊素材审核记录" name="approvalHistory">
        <history :advertiserId="query.advertiserId"></history>
      </TabPane>
    </Tabs>

  </div>
</template>

<script>
import {
  getAdvertiser,
  getAdvertiserHistoryList
} from '@/api/crm/advertiser'
import { getOMorderpage, setordernotinstall, finishrefund } from '@/api/order/om/order'

import {
  formatOrderStatus,
  formatRefundStatus,
  formatApprovalResult
} from '@/utils/tagStatus'

import {
  GetCurrentSchedule
} from '@/utils/dateFormat'
import History from './components/History'
export default {
  components: {
    History
  },
  props: {
    id: {
      type: Number
    }
  },
  data () {
    return {
      reportBrandModal: false,
      reprotcompanyId: 0, // 选中的报备渠道Id
      reportBrandId: 0,
      reportCompanyArray: [], // 可报备的渠道

      EditBrandIndustry: false,
      industryId: '',
      currentBrandId: '',
      approvalHistory: [],
      loadingAddBrand: false,

      approvalHistoryColumn: [
        {
          title: '审批结果',
          key: 'approvalResult',
          align: 'center',
          render: (h, params) => {
            return formatApprovalResult(
              h,
              parseInt(params.row.approvalResult),
              params.row.approvalResultName
            )
          }
        },
        {
          title: '审批状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return formatApprovalResult(
              h,
              parseInt(params.row.status),
              params.row.statusName
            )
          }
        },
        { title: '审批节点', key: 'approvalnodeName' },
        { title: '审批人', key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        { title: '审批备注', key: 'comment' }
      ],
      contacts: [],
      brands: [],

      industrydata: [],
      modalAddBrand: false,
      advertiser: {
        id: null,
        name: '',
        orgCode: '',
        address: '',
        industryId: null,
        type: null,
        tel: '',
        description: '',
        userId: '',
        createTime: '',
        updateTime: ''
      },

      orders: [],
      query: {
        publisherId: '',
        pageNumber: 1,
        pageSize: 10
      },
      total: 0,
      orderColumn: [

        { title: '订单编号', key: 'orderCode' },
        {
          title: '投放档期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', GetCurrentSchedule(
              params.row.startDate,
              params.row.endDate))
          }

        },
        // { title: '品牌关键字', key: 'keyword' },
        {
          title: '采购资源统计',
          render: (h, params) => {
            const spans = []
            params.row.resourceTypeVOList.forEach(resource => {
              spans.push(h(
                'span',
                resource.resourcetypeName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                '*' + resource.count
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return params.row.refundStatus > 0 ? formatRefundStatus(h, params.row.refundStatus, params.row.refundStatusName) : formatOrderStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },

        { title: '锁位到期时间', key: 'expireTime' },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 200,
          render: (h, params) => {
            return h('div', [
            //   params.row.status === 7 ? h(
            //     'a',
            //     {
            //       style: {
            //         marginRight: '5px'
            //       },
            //       on: {
            //         click: () => {
            //           this.$Modal.confirm({
            //             title: '操作确认？',
            //             content:
            //             '确定当前订单已完成收款？',
            //             loading: true,
            //             onOk: () => {
            //               this.finishCollection(params.row)
            //               this.$Modal.remove()
            //             }
            //           })
            //         }
            //       }
            //     },
            //     '完成收款'
            //   ) : null,
            //   params.row.refundStatus === 1 ? h(
            //     'a',
            //     {
            //       style: {
            //         marginRight: '5px',
            //         color: 'red'
            //       },
            //       on: {
            //         click: () => {
            //           this.$Modal.confirm({
            //             title: '操作确认？',
            //             content:
            //             '确定当前订单已完成退款工作？',
            //             loading: true,
            //             onOk: () => {
            //               this.finishRefund(params.row)
            //               this.$Modal.remove()
            //             }
            //           })
            //         }
            //       }
            //     },
            //     '完成退款'
            //   ) : null
            ])
          }
        }
      ]
    }
  },
  methods: {
    initAdvertiserInfo () {
      this.query.advertiserId = this.detailId
      getAdvertiser(this.query).then((res) => {
        this.advertiser = res
        this.query.publisherId = res.publisherId
        this.initOrders()
      })
    },
    initOrders () {
      getOMorderpage(this.query).then((res) => {
        this.orders = res.records
        this.total = res.total
      })
    },

    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initOrders()
    },

    initAdvertiserHistories () {
      getAdvertiserHistoryList({ advertiserId: this.detailId }).then((res) => {
        this.advertiserHistory = res
      })
    },
    // 完成收款
    finishCollection (params) {
      setordernotinstall({ orderId: params.orderId }).then(res => {
        this.initOrders()
      })
    },
    // 完成退款
    finishRefund (params) {
      finishrefund({ orderId: params.orderId }).then(res => {
        this.initOrders()
      })
    },
    showAddBrandModal () {
      this.modalAddBrand = true
    }
  },
  created () {
    this.initAdvertiserInfo()
  },
  computed: {
    detailId () {
      return this.id || this.$store.state.advertiser.detailId
    }
  }
}
</script>
